// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
    production: true,
    userKey: 'client',
    userTokenKey: 'user-token',
    baseUrlApi: "https://bo.yenbeexpertisecomptable.fr/api",
    filesUrl: 'https://bo.yenbeexpertisecomptable.fr/',
    //baseUrlApi: "https://darkgreen-eland-345513.hostingersite.com/bo/api",
    //filesUrl: 'https://darkgreen-eland-345513.hostingersite.com/bo/',
    //baseUrlApi : "http://localhost:8888/api",
    //filesUrl: 'http://localhost/crmexpertise/backend/public/',
    baseUrlFront: "http://localhost:4200/#",
    baseUrlFrontoffice: "http://localhost:4200/#",
    baseUrlPWA: "http://localhost:4200/#",
    baseUrlOrganizerBackoffice: "http://localhost:4200/#",
    filesUrlAdminBackOffice: 'https://eventizer-dev.s3.eu-west-3.amazonaws.com/',
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
